// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, UserCredential, signOut, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, getDocs, query, where, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore/lite';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCVY7Sz8l99LSwi9HIHk5YBZpaEPqB2C20",
  authDomain: "kriya-mascot.firebaseapp.com",
  projectId: "kriya-mascot",
  storageBucket: "kriya-mascot.appspot.com",
  messagingSenderId: "396822204011",
  appId: "1:396822204011:web:c6a7a183f71ae1cb4d6b2e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

const mediaCollection = collection(db, 'Media');
const blogCollection = collection(db, 'Blog');
const linkCollection = collection(db, 'Link');
const publicGlobalDoc = doc(db, 'GlobalFootprint', 'published');
const draftGlobalDoc = doc(db, 'GlobalFootprint', 'draft');


export interface Media {
  id: string;
  year: string;
  title: string;
  description: string;
  media: string;
  link: string;
  published: boolean;
};

export interface Blog {
  id: string;
  title: string;
  image: string;
  description: string;
  link: string;
  published: boolean;
};

export interface GlobalFootprint {
  id: string;
  patients: string;
  tests: string;
  centers: string;
  devices: string;
};

export interface Link {
  id: string;
  link: string;
  download: number;
};

class DataManager {

  status: boolean = false;

  loginStatus(callback: (status: boolean) => void) {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.status = true;
      } else {
        this.status = false;
      }
      callback(this.status);
    });
  }

  async login(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, email, password);
  }

  async logout() {
    signOut(auth).then(() => {
      this.status = false;
    }).catch((error) => {
      console.log(error);
    });
  }

  async getMedias(draft: boolean): Promise<Array<Media>> {
    const q = (draft) ? query(mediaCollection) : query(mediaCollection, where("published", "==", true));
    const docSnaps = await getDocs(q);
    let items = Array<Media>();
    docSnaps.forEach((doc) => {
      items.push(doc.data() as Media);
    });
    return new Promise((resolve, reject) => {
      resolve(items);
    });
  }

  async getGlobalFootprint(id: string): Promise<GlobalFootprint> {
    const draft = (id === 'draft');
    const globalDoc = (draft) ? draftGlobalDoc : publicGlobalDoc;
    const docSnap = await getDoc(globalDoc);
    if (docSnap.exists()) {
      return new Promise((resolve, reject) => {
        resolve(docSnap.data() as GlobalFootprint);
      });
    } else {
      return new Promise((resolve, reject) => {
        reject("Doc does not exist.");
      });
    }
  }

  async getLinks(): Promise<Array<Link>> {
    const docSnaps = await getDocs(linkCollection);
    let items = Array<Link>();
    docSnaps.forEach((doc) => {
      items.push(doc.data() as Link);
    });
    return new Promise((resolve, reject) => {
      resolve(items);
    });
  }

  async getBlogs(draft: boolean): Promise<Array<Blog>> {
    const q = (draft) ? query(blogCollection) : query(blogCollection, where("published", "==", true));
    const docSnaps = await getDocs(q);
    let items = Array<Blog>();
    docSnaps.forEach((doc) => {
      items.push(doc.data() as Blog);
    });
    return new Promise((resolve, reject) => {
      resolve(items);
    });
  }

  async updateMedia(media: Media) {
    const mediaDoc = doc(db, 'Media', media.id);
    await setDoc(mediaDoc, media);
  }

  async updateBlog(blog: Blog) {
    const blogDoc = doc(db, 'Blog', blog.id);
    await setDoc(blogDoc, blog);
  }

  async updateLink(link: Link) {
    const linkDoc = doc(db, 'Link', link.id);
    await setDoc(linkDoc, link);
  }

  async updateGlobalFootprint(global: GlobalFootprint, draft: boolean) {
    global.id = (draft) ? 'draft' : 'published';
    const globalDoc = (draft) ? draftGlobalDoc : publicGlobalDoc;
    await setDoc(globalDoc, global);
  }

  async deleteMedia(id: string) {
    const mediaDoc = doc(db, 'Media', id);
    await deleteDoc(mediaDoc);
  }

  async deleteBlog(id: string) {
    const blogDoc = doc(db, 'Blog', id);
    await deleteDoc(blogDoc);
  }

  getFile(path: string, callback: (url: string) => void) {
    const imageRef = ref(storage, path);
    getDownloadURL(imageRef).then((url) => { callback(url); });
  }

  uploadFile(path: string, file: File, callback: (status: boolean) => void) {
    const imageRef = ref(storage, path);
    uploadBytes(imageRef, file, { contentType: file.type }).then((snapshot) => {
      callback(true);
    }).catch((error) => {
      callback(false)
    });
  }

  generateId(): string {
    return crypto.randomUUID();
  }

};

const dataManager = new DataManager();

export default dataManager;