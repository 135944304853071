import { useEffect, useState } from "react";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import dataManager from "../../DataManager";


function AdminHome() {
  const [user, setUser] = useState(false);

  useEffect(() => {
    dataManager.loginStatus((status) => {
      setUser(status);
    });
  }, []);

  const openMedia = () => {
    window.location.href = '/admin/media/' + dataManager.generateId();
  };

  const openBlog = () => {
    window.location.href = '/admin/blog/' + dataManager.generateId();
  };

  const openLink = () => {
    window.location.href = '/admin/link/' + dataManager.generateId();
  };

  const openGlobal = () => {
    window.location.href = '/admin/global';
  };

  const logout = () => {
    dataManager.logout();
  };

  if (user) {
    return (
      <Container>
        <div style={{ height: "20px" }}></div>
        <Row>
          <Col>
            <div>
              <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "190px", borderRadius: "10px" }} onClick={() => { openMedia(); }}>
                <div style={{ textTransform: "uppercase" }}>Create New Media</div>
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "190px", borderRadius: "10px" }} onClick={() => { openBlog(); }}>
                <div style={{ textTransform: "uppercase" }}>Create New Blog</div>
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "210px", borderRadius: "10px" }} onClick={() => { openGlobal(); }}>
                <div style={{ textTransform: "uppercase" }}>Create New Global</div>
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "65px", borderRadius: "10px" }} onClick={() => { openLink(); }}>
                <div style={{ textTransform: "uppercase" }}>Link</div>
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "95px", borderRadius: "10px" }} onClick={() => { logout(); }}>
                <div style={{ textTransform: "uppercase" }}>Logout</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (<Login />);
  }
}


function Login() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const appLogin = () => {
    dataManager.login(email, password);
  };

  return (
    <Container>
      <Form>
        <div style={{ height: "10px" }}></div>
        <FormGroup>
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="" name="email"
            value={email}
            onChange={(e) => { setEmail(e.target.value) }} />
        </FormGroup>
        <div style={{ height: "10px" }}></div>
        <FormGroup>
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="" name="password"
            value={password}
            onChange={(e) => { setPassword(e.target.value) }} />
        </FormGroup>
        <div style={{ height: "20px" }}></div>
        <div>
          <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "95px", borderRadius: "10px" }} onClick={() => { appLogin(); }}>
            <div style={{ textTransform: "uppercase" }}>Login</div>
          </div>
        </div>
      </Form>
    </Container>
  );
}

export default AdminHome;