import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import dataManager, { GlobalFootprint } from "../../DataManager";

export interface GlobalProps {
  id: string;
  publish: () => void;
}

function Global(props: GlobalProps) {
  const [global, setGlobal] = useState({
    id: props.id,
    patients: "70M",
    tests: "300M",
    centers: "3000+",
    devices: "360M"
  } as GlobalFootprint);

  useEffect(() => {
    dataManager.getGlobalFootprint(props.id)
      .then((item) => { setGlobal(item); });
  }, [props.id]);

  const publishGlobal = () => {
    dataManager.updateGlobalFootprint(global, false).then(() => { props.publish(); });
  }

  const edit = () => {
    window.location.href = '/admin/global';
  }

  const DraftAction = (props.id === 'draft') ? (<div>
    <div style={{ height: "10px" }}></div>
    <Row>
      <Col>
        <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "95px", borderRadius: "10px" }} onClick={() => { edit(); }}>
          <div style={{ textTransform: "uppercase" }}>Edit</div>
        </div>
      </Col>
      <Col>
        <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "95px", borderRadius: "10px" }} onClick={() => { publishGlobal(); }}>
          <div style={{ textTransform: "uppercase" }}>Publish</div>
        </div>
      </Col>
    </Row>
    <div style={{ height: "10px" }}></div>
  </div>) : (<></>);

  return (
    <>
      <Container className="mobile-padding mt-5 mb-5 text-center">
        <Row>
          {DraftAction}
        </Row>
        <Row>
          <Col>
            <div className="mb-5">
              <h1 className="pt-2 pb-1 sub-title" style={{ fontWeight: 700 }}>
                Our Global Footprint
              </h1>
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            lg={3}
            className="d-flex flex-column  text-align-center justify-content-center align-items-center"
          >
            <img
              className="mb-4"
              src="/images/homepage/icon_patien.svg"
              alt="love"
              width={94}
              height={94}
            />
            <div
              className="img-bold-font"
            >
              {global.patients}
            </div>
            <p className="img-sub-title">
              Patient lives <br /> touched
            </p>
          </Col>
          <Col
            xs={12}
            lg={3}
            className="d-flex flex-column  text-align-center justify-content-center align-items-center"
          >
            <img
              className="mb-4"
              src="/images/homepage/icon_pathlogy.svg"
              alt="love"
              width={94}
              height={94}
            />
            <div
              className="img-bold-font"
            >
              {global.tests}
            </div>
            <p className="img-sub-title">
              Pathology tests <br /> enabled
            </p>
          </Col>
          <Col
            xs={12}
            lg={3}
            className="d-flex flex-column  text-align-center justify-content-center align-items-center"
          >
            <img
              className="mb-4"
              src="/images/homepage/icon_covid.svg"
              alt="love"
              width={94}
              height={94}
            />
            <div
              className="img-bold-font"
            >
              {global.centers}
            </div>
            <p className="img-sub-title">
              National Lab Collection Centers Supplied
            </p>
          </Col>
          <Col
            xs={12}
            lg={3}
            className="d-flex flex-column  text-align-center justify-content-center align-items-center"
          >
            <img
              className="mb-4"
              src="/images/homepage/icon_device_manufacture.svg"
              alt="love"
              width={94}
              height={94}
            />
            <div
              className="img-bold-font"
            >
              {global.devices}
            </div>
            <p className="img-sub-title">
              Devices manufactured <br /> annually
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Global;
//<img src="/images/homepage/3M.png" alt="60M" width={71} height={40} />