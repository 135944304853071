import { useEffect, useState } from "react";
import dataManager, { Blog } from "../../DataManager";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { BlogCard, BlogPapers } from "../Service/Services";


function BlogEditor() {
  let { id } = useParams();
  const [seed, setSeed] = useState(0);
  const [blog, setBlog] = useState({
    id: id,
    title: "",
    image: "",
    description: "",
    link: "",
    published: false
  } as Blog);

  const [blogs, setBlogs] = useState(new Array<Blog>());

  const updateData = () => {
    dataManager.loginStatus((status) => {
      if (status) {
        dataManager.getBlogs(true)
          .then((items) => {
            setBlogs(items);
            setSeed(Math.random());
            items.forEach((item) => {
              if (item.id === id) {
                setBlog(item);
              }
            })
          });
      } else {
        window.location.href = '/admin';
      }
    });
  }

  useEffect(() => {
    updateData();
  }, []);


  const newBlog = () => {
    window.location.href = '/admin/blog/' + dataManager.generateId();
  };

  const uploadFile = (files: FileList | null) => {
    if (files) {
      const file = files[0];
      if (file) {
        const filePath = "images/" + dataManager.generateId();
        dataManager.uploadFile(filePath, file, (path) => {
          setBlog({ ...blog, image: filePath });
        });
      }
    }
  };

  const updateBlog = () => {
    dataManager.updateBlog(blog).then(() => { alert("Blog saved."); updateData(); })
  };

  return (
    <>
      <Container>
        <div style={{ height: "20px" }}></div>
        <Row>
          <Col>
            <div>
              <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "190px", borderRadius: "10px" }} onClick={() => { newBlog(); }}>
                <div style={{ textTransform: "uppercase" }}>Create New Blog</div>
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "190px", borderRadius: "10px" }} onClick={() => { window.location.href = '/admin'; }}>
                <div style={{ textTransform: "uppercase" }}>Back Home</div>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ height: "20px" }}></div>
        <Form>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" placeholder="" name="title"
              value={blog.title}
              onChange={(e) => { setBlog({ ...blog, title: e.target.value }); }} />
          </FormGroup>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" placeholder="" name="description"
              value={blog.description}
              onChange={(e) => { setBlog({ ...blog, description: e.target.value }); }} />
          </FormGroup>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Link</Form.Label>
            <Form.Control type="text" placeholder="" name="link"
              value={blog.link}
              onChange={(e) => { setBlog({ ...blog, link: e.target.value }); }} />
          </FormGroup>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Image Attachment</Form.Label>
            <Form.Control type="file" accept=".png" name="image" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { uploadFile(e.target.files); }}></Form.Control>
          </FormGroup>
          <div style={{ height: "20px" }}></div>
          <div>
            <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "95px", borderRadius: "10px" }} onClick={() => { updateBlog(); }}>
              <div style={{ textTransform: "uppercase" }}>Update</div>
            </div>
          </div>
        </Form>
        <div style={{ height: "20px" }}></div>
        <div>
          <div className="blog-holder">
            <BlogCard blog={blog} edit={false} publish={() => { }} deleted={() => { }} />
          </div>
        </div>
      </Container>
      <Container>
        <div style={{ height: "20px" }}></div>
        <div style={{ textTransform: "uppercase" }}><h2>All Records (Draft & Published)</h2></div>
        <div>
          <div className="blog-holder">
            {blogs.map((blog, index) => {
              return (<BlogCard blog={blog} edit={true} key={index} publish={() => { alert('Blog published'); updateData(); }} deleted={() => { alert("Blog deleted."); updateData(); }} />);
            })}
          </div>
        </div>
        <div style={{ height: "20px" }}></div>
      </Container>
      <Container>
        <div style={{ textTransform: "uppercase" }}><h2>Published Records</h2></div>
        <div style={{ height: "20px" }}></div>
        <BlogPapers key={seed} />
      </Container>
    </>
  );
}

export default BlogEditor;