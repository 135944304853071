import { useEffect, useState } from "react";
import dataManager, { Link } from "../../DataManager";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

function LinkEditor() {
  let { id } = useParams();
  const [link, setLink] = useState({
    id: id,
    link: "",
  } as Link);
  const [links, setLinks] = useState(new Array<Link>())

  const slug = (name: string): string => {
    return name
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple hyphens with a single hyphen
      .replace(/^-+/, '') // Trim hyphens from the start
      .replace(/-+$/, '') // Trim hyphens from the end
      .replace("pdf", ".pdf");//Add extension.
  }

  const uploadFile = (files: FileList | null) => {
    if (files) {
      const file = files[0];
      if (file) {
        const fileName = slug(file.name);
        const filePath = "pdfs/" + fileName;
        dataManager.uploadFile(filePath, file, (path) => {
          const linkObj = { id: fileName, link: filePath } as Link;
          setLink(linkObj);
          uploadLink(linkObj);
        });
      }
    }
  };

  const uploadLink = (link: Link) => {
    dataManager.updateLink(link).then(() => { alert("Link saved."); window.location.href = '/admin/link/' + dataManager.generateId(); })
  }

  const updateData = () => {
    dataManager.loginStatus((status) => {
      if (status) {
        dataManager.getLinks()
          .then((items) => {
            setLinks(items);
          });
      } else {
        window.location.href = '/admin';
      }
    });
  }

  useEffect(() => {
    updateData();
  }, []);

  return (
    <>
      <Container>
        <Form>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Image Attachment</Form.Label>
            <Form.Control type="file" accept=".pdf" name="image" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { uploadFile(e.target.files); }}></Form.Control>
          </FormGroup>
          <div style={{ height: "10px" }}></div>
        </Form>
      </Container>
      <Container>
        <div style={{ height: "20px" }}></div>
        <div style={{ textTransform: "uppercase" }}><h2>All Link Records</h2></div>
        <div style={{ height: "20px" }}></div>
        {
          links.map((item, index) => {
            return (<LinkView link={item} key={index} />);
          })
        }
      </Container>
    </>
  );
}

interface LinkProps {
  link: Link
};

function LinkView(props: LinkProps) {
  const link = props.link;
  return (
    <>
      <Row>
        <Col>
          {link.link}
        </Col>
        <Col>
          https://{window.location.host}/files/pdf/{link.id}
        </Col>
      </Row>
    </>
  );
}

function DownloadFile() {

  let { id } = useParams()

  const filePath = "pdfs/" + id;
  const [fileUrl, setFileUrl] = useState("");

  const downloadFile = () => {
    dataManager.getFile(filePath, (url) => {
      setFileUrl(url);
      window.location.href = url;
    });
  }

  useEffect(() => {
    downloadFile();
  }, []);

  return (
    <>
      <div>
        <div style={{ height: "20px" }}></div>
        <div>Your download will start shortly. If it does not start automatically, click <a href={fileUrl} download={id} style={{ background: "#000", textTransform: "uppercase" }}>here</a></div>
        <div style={{ height: "20px" }}></div>
      </div>
    </>
  );
}

export default LinkEditor;

export { DownloadFile };