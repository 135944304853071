import { useEffect, useState } from "react";
import { Container, Form, FormGroup } from "react-bootstrap";
import dataManager, { GlobalFootprint } from "../../DataManager";
import Global from "../Homepage/Global";


function GlobalEditor() {

  const [global, setGlobal] = useState({
    id: 'draft',
    patients: "70M",
    tests: "300M",
    centers: "3000+",
    devices: "360M"
  } as GlobalFootprint);

  useEffect(() => {

    dataManager.loginStatus((status) => {
      if (status) {
        //do nothing.
      } else {
        window.location.href = '/admin';
      }
    });

    dataManager.getGlobalFootprint('draft')
      .then((item) => { setGlobal(item); });
  }, []);

  const updateGlobal = (name: string, value: string) => {
    setGlobal({ ...global, [name]: value });
  };

  const save = () => {
    dataManager.updateGlobalFootprint(global, true).then(() => { alert("Global footprint saved."); window.location.reload(); });
  }

  return (
    <>
      <div style={{ height: "20px" }}></div>
      <div>
        <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "190px", borderRadius: "10px" }} onClick={() => { window.location.href = '/admin'; }}>
          <div style={{ textTransform: "uppercase" }}>Back Home</div>
        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <Container>
        <Form>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Patients</Form.Label>
            <Form.Control type="text" placeholder="" name="patients"
              value={global.patients} onChange={(e) => { updateGlobal(e.target.name, e.target.value); }}></Form.Control>
          </FormGroup>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Tests</Form.Label>
            <Form.Control type="text" placeholder="" name="tests" value={global.tests} onChange={(e) => { updateGlobal(e.target.name, e.target.value); }}></Form.Control>
          </FormGroup>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Centers</Form.Label>
            <Form.Control type="text" placeholder="" name="centers" value={global.centers} onChange={(e) => { updateGlobal(e.target.name, e.target.value); }}></Form.Control>
          </FormGroup>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Devices</Form.Label>
            <Form.Control type="text" placeholder="" name="devices" value={global.devices} onChange={(e) => { updateGlobal(e.target.name, e.target.value); }}></Form.Control>
          </FormGroup>
          <div style={{ height: "20px" }}></div>
          <div>
            <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "95px", borderRadius: "10px" }} onClick={() => { save(); }}>
              <div style={{ textTransform: "uppercase" }}>Save</div>
            </div>
          </div>
        </Form>
      </Container>
      <Container>
        <div style={{ textTransform: "uppercase" }}><h2>All Records (Draft & Published)</h2></div>
        <Global id="draft" publish={() => { alert('Global footprint published.'); window.location.reload(); }} />
        <div style={{ height: "20px" }}></div>
        <div style={{ textTransform: "uppercase" }}><h2>Published Records</h2></div>
        <Global id="published" publish={() => { }} />
      </Container>
    </>
  );
}

export default GlobalEditor;