import { useEffect, useState } from "react";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import dataManager, { Media } from "../../DataManager";
import { useParams } from "react-router-dom";
import TheMedia from "../Homepage/TheMedia";


function MediaEditor() {
  let { id } = useParams();
  const [seed, setSeed] = useState(0);
  const [media, setMedia] = useState({
    id: id,
    year: "",
    title: "",
    description: "",
    media: "",
    link: "",
    published: false
  } as Media);
  const [medias, setMedias] = useState(new Array<Media>());

  const updateData = () => {
    dataManager.loginStatus((status) => {
      if (status) {
        dataManager.getMedias(true)
          .then((items) => {
            setMedias(items);
            setSeed(Math.random());
            items.forEach((item) => {
              if (item.id === id) {
                setMedia(item);
              }
            })
          });
      } else {
        window.location.href = '/admin';
      }
    });
  };

  const updateMedia = () => {
    dataManager.updateMedia(media).then(() => { alert('Media saved.'); updateData(); });
  };

  const newMedia = () => {
    window.location.href = '/admin/media/' + dataManager.generateId();
  };

  useEffect(() => {
    updateData();
  }, []);

  return (
    <>
      <Container>
        <div style={{ height: "20px" }}></div>
        <Row>
          <Col>
            <div>
              <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "190px", borderRadius: "10px" }} onClick={() => { newMedia(); }}>
                <div style={{ textTransform: "uppercase" }}>Create New Media</div>
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "190px", borderRadius: "10px" }} onClick={() => { window.location.href = '/admin'; }}>
                <div style={{ textTransform: "uppercase" }}>Back Home</div>
              </div>
            </div>
          </Col>
        </Row>

        <div style={{ height: "20px" }}></div>
        <Form>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" placeholder="" name="title"
              value={media.title}
              onChange={(e) => { setMedia({ ...media, title: e.target.value }); }} />
          </FormGroup>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" placeholder="" name="description"
              value={media.description}
              onChange={(e) => { setMedia({ ...media, description: e.target.value }); }} />
          </FormGroup>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Media Name</Form.Label>
            <Form.Control type="text" placeholder="" name="media"
              value={media.media}
              onChange={(e) => { setMedia({ ...media, media: e.target.value }); }} />
          </FormGroup>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Year</Form.Label>
            <Form.Control type="text" placeholder="" name="year"
              value={media.year}
              onChange={(e) => { setMedia({ ...media, year: e.target.value }); }} />
          </FormGroup>
          <div style={{ height: "10px" }}></div>
          <FormGroup>
            <Form.Label>Link</Form.Label>
            <Form.Control type="text" placeholder="" name="link"
              value={media.link}
              onChange={(e) => { setMedia({ ...media, link: e.target.value }); }} />
          </FormGroup>
          <div style={{ height: "20px" }}></div>
          <div>
            <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "95px", borderRadius: "10px" }} onClick={() => { updateMedia(); }}>
              <div style={{ textTransform: "uppercase" }}>Update</div>
            </div>
          </div>
        </Form>
        <div style={{ height: "20px" }}></div>
        <div>
          <div style={{ flex: 2, alignContent: "center", justifyContent: "center" }}>
            <div style={{ display: "flex", gap: "80px" }} onClick={() => { window.open(media.link); }}>
              <div style={{ color: "#914082" }}><b>{media.year}</b></div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ color: "#914082", textTransform: "uppercase", cursor: "pointer" }}><b>{media.title}</b></div>
                <div style={{ textTransform: "uppercase" }}><b>{media.media}</b></div>
                <div><p></p><p></p>{media.description}</div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div style={{ height: "20px" }}></div>
        <div style={{ textTransform: "uppercase" }}><h2>All Records (Draft & Published)</h2></div>
        <div>
          <div style={{ flex: 2, alignContent: "center", justifyContent: "center" }}>
            <div style={{ padding: "40px", height: "400px", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "80px" }}>
              {medias.map((media, index) => {
                return (
                  <MediaCard media={media} key={index} publish={() => { alert('Media published.'); updateData(); }} deleted={() => { alert("Media deleted."); updateData(); }} />
                );
              })}

            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div style={{ textTransform: "uppercase" }}><h2>Published Records</h2></div>
        <div style={{ height: "20px" }}></div>
        <TheMedia key={seed} />
      </Container>
    </>
  );
}

interface MediaProps {
  media: Media;
  publish: () => void;
  deleted: () => void;
}

function MediaCard(props: MediaProps) {
  const media = props.media;

  const deleteMedia = () => {
    dataManager.deleteMedia(media.id).then(() => { props.deleted(); });
  }

  const publishMedia = () => {
    let item = media;
    item.published = true;
    dataManager.updateMedia(item).then(() => { props.publish(); });
  }

  const edit = () => {
    window.location.href = '/admin/media/' + media.id;
  }

  return (
    <div>
      <div>
        <div style={{ height: "10px" }}></div>
        <Row>
          <Col>
            <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "120px", borderRadius: "10px" }} onClick={() => { edit(); }}>
              <div style={{ textTransform: "uppercase" }}>Edit</div>
            </div>
          </Col>
          <Col>
            <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "120px", borderRadius: "10px" }} onClick={() => { publishMedia(); }}>
              <div style={{ textTransform: "uppercase" }}>Publish</div>
            </div>
          </Col>
          <Col>
            <div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "120px", borderRadius: "10px" }} onClick={() => { deleteMedia(); }}>
              <div style={{ textTransform: "uppercase" }}>Delete</div>
            </div>
          </Col>
        </Row>
        <div style={{ height: "10px" }}></div>
      </div>
      <div style={{ display: "flex", gap: "80px" }} onClick={() => { window.open(media.link); }}>
        <div style={{ color: "#914082" }}><b>{media.year}</b></div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ color: "#914082", textTransform: "uppercase", cursor: "pointer" }}><b>{media.title}</b></div>
          <div style={{ textTransform: "uppercase" }}><b>{media.media}</b></div>
          <div><p></p><p></p>{media.description}</div>
        </div>
      </div>
    </div>
  );
}

export default MediaEditor;