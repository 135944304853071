import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Homepage from "./routes/Homepage";
import Company from "./routes/Company";
import Products from "./routes/Products";
import Impact from "./routes/Impact";
import RandD from "./routes/RandD";
import { Fragment } from "react";
import TrivusDetail from "./routes/TrivusDetail";
import OurServices from "./routes/OutServices";
import { BlogCertification, BlogRTPCR, BlogTrivus, ContactPage } from "./routes/PTrivus";
import BlogEditor from "./components/Admin/BlogEditor";
import GlobalEditor from "./components/Admin/GlobalEditor";
import MediaEditor from "./components/Admin/MediaEditor";
import AdminHome from "./components/Admin/Login";
import LinkEditor, { DownloadFile } from "./components/Admin/LinkEditor";
//import Career from "./routes/Career";

function App() {
  return (
    <Fragment>
      <Routes>
        <Route index element={<Homepage />} />
        <Route path="company" element={<Company />} />
        <Route path="products/biosample" element={<Products name="biosample" />} />
        <Route path="products/molecular" element={<Products name="molecular" />} />
        <Route path="products/diabetes" element={<Products name="diabetes" />} />
        <Route path="impact" element={<Impact />} />
        <Route path="r_d" element={<RandD />} />
        <Route path="services" element={<OurServices />} />
        <Route path="trivus" element={<TrivusDetail />} />
        <Route path="blog_trivus" element={<BlogTrivus />} />
        <Route path="blog_rtpcr" element={<BlogRTPCR />} />
        <Route path="blog_certification" element={<BlogCertification />} />
        <Route path="contact_us" element={<ContactPage />} />
        <Route path="admin" element={<AdminHome />} />
        <Route path="admin/blog/:id" element={<BlogEditor />} />
        <Route path="admin/media/:id" element={<MediaEditor />} />
        <Route path="admin/link/:id" element={<LinkEditor />} />
        <Route path="admin/global" element={<GlobalEditor />} />
        <Route path="files/pdf/:id" element={<DownloadFile />} />
      </Routes>
    </Fragment>
  );
}
//<Route path="career" element={<Career />} />
export default App;
